import authService from '../../../services/auth'
import { USER_STATUS } from '../../../misc/commons'
import ChangePassword from './change-pw'

export default {
    components: {
        ChangePassword
    },
    data() {
        return {           
            user_status: USER_STATUS,
            user: {
                fname: '',
                lname: '',
                email: '',
                title: '',                
                companyname: '',
                phoneno: ''
            },
            changePasswordKey: 0
        }
    },
    created() {
       console.clear();
      },
    mounted() {
        this.fetchProfile()
    },
    methods: {
        Dashboard(){
            this.$router.push({name: 'Recalls'})
          },
        editProfile() {
            this.$router.push({name: 'Edit Account'})
        },
        fetchProfile() {
                var storedNames = JSON.parse(localStorage.getItem("userD"));
                this.user.fname = storedNames[1];
                this.user.lname = storedNames[2];
                this.user.email = storedNames[3];
                this.user.title = storedNames[4];
                this.user.companyname = storedNames[5];
                this.user.phoneno = storedNames[6];
        },
        openPasswordModal (){
            this.changePasswordKey += 1
            this.$nextTick(()=>{
                this.$refs.changePassword.forceRerender()
            })
        }
    }
}